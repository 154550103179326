<template>
  <div>
    <div>
      <KTPortlet title="View assigned devices">
        <template v-slot:toolbar>
          <v-btn
            v-if="devices !== null"
            color="success"
            @click="$refs.groupSetup.show()"
            >ADD DEVICE</v-btn
          >
          <!-- <div
            class="dropdown dropdown-inline"
            v-if="hasDevicesCache && devices !== null"
          >
            <button
              type="button"
              class="btn btn-clean btn-sm btn-icon btn-icon-md"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="flaticon2-settings"></i>
            </button>
            <div
              v-if="hasDevicesCache && devices !== null"
              class="
                dropdown-menu
                dropdown-menu-right
                dropdown-menu-fit
                dropdown-menu-md
              "
            >
              <div style="padding: 20px">
                <b-button
                  style="
                    margin-left: 10px;
                    margin-bottom: 10px;
                    padding: 5px 10px 5px 10px;
                    color: #ffff;
                  "
                  variant="info"
                  @click="updateData(true)"
                >
                  <v-icon style="color: #ffffff">mdi-refresh</v-icon>
                  Force update data
                </b-button>
                < !-- <b-form-group label="View">
                  <b-form-radio v-model="showTreeView" size="lg" :value="false"
                    >Table view</b-form-radio
                  >
                  <b-form-radio v-model="showTreeView" size="lg" :value="true"
                    >Hierarchical view</b-form-radio
                  >
                </b-form-group> -- >
              </div>
            </div>
          </div> -->
        </template>
        <template slot="body">
          <Loader v-if="devices === null" :inContent="true">Loading...</Loader>
          <div
            v-else-if="devices.length === 0"
            style="width: 100%; text-align: center"
          >
            No devices to display
          </div>
          <b-table-simple v-else responsive>
            <b-thead>
              <b-tr>
                <b-th>Group</b-th>
                <b-th>Name</b-th>
                <b-th>Status</b-th>
                <!-- <b-th>Last IP</b-th> -->
                <b-th>OS/Firmware Version</b-th>
                <b-th v-if="containsAssignedTemplates">Assigned Template</b-th>
                <b-th v-if="containsCustomGateways">Network Gateway</b-th>
                <b-th>Actions</b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              v-for="groupedDevice in groupedDevices"
              v-bind:key="groupedDevice.groupId"
            >
              <b-tr v-if="!$tools.isNullOrUndefined(groupedDevice.group)">
                <b-th sticky-column>{{ groupedDevice.group.name }}</b-th>
                <b-td :colspan="3"> </b-td>
                <b-td v-if="containsAssignedTemplates"> </b-td>
                <b-td v-if="containsCustomGateways"> </b-td>
                <b-td> </b-td>
              </b-tr>
              <b-tr
                v-for="device in groupedDevice.devices"
                v-bind:key="device.id"
              >
                <b-td> </b-td>
                <b-th>{{ device.name }}</b-th>
                <b-td v-if="device.authorized === false">
                  <v-chip
                    class="ma-2"
                    :style="`background: var(--warning); color: #fff;`"
                    >Pending adoption</v-chip
                  >
                </b-td>
                <b-td v-else-if="device.active === false">
                  <v-chip
                    class="ma-2"
                    :style="`background: var(--danger); color: #fff;`"
                    >Device disabled</v-chip
                  >
                </b-td>
                <b-td v-else-if="`${device.lastInform}` === '0'">
                  <v-chip
                    class="ma-2"
                    :style="`background: var(--info); color: #fff;`"
                    >Pending first upload</v-chip
                  >
                </b-td>
                <b-td v-else>
                  <deviceState :canClickTools="false" :device="device" />
                </b-td>
                <!-- <b-td>[{{GetRouterType(device.routerTypeId).name}}] {{GetRouterType(device.routerTypeId).make}} {{GetRouterType(device.routerTypeId).model}}</b-td> -->
                <!-- <b-td>{{
                  !$tools.isNullOrUndefined(device.type) &&
                  !$tools.isNullOrUndefined(device.type.brand)
                    ? device.type.brand.toUpperCase()
                    : ""
                }}</b-td> -->
                <!-- <b-td>{{ device.lastIP }}</b-td> -->
                <b-td>
                  <span
                    v-if="
                      device.osVersion === '' && device.firmwareVersion == ''
                    "
                  >
                    -
                  </span>
                  <span>{{ device.osVersion }}</span>
                  <span
                    v-if="
                      device.osVersion !== '' && device.firmwareVersion !== ''
                    "
                  >
                    /
                  </span>
                  <span>{{ device.firmwareVersion }}</span>
                </b-td>
                <template v-if="containsAssignedTemplates">
                  <b-td v-if="GetTemplate(device.templateId) === null"
                    >No assigned template</b-td
                  >
                  <b-td v-else>
                    <router-link
                      :to="`/Networks/${$route.params.serviceId}/Templates/${
                        GetTemplate(device.templateId).id
                      }`"
                    >
                      <b-button variant="default">{{
                        GetTemplate(device.templateId).name
                      }}</b-button>
                    </router-link>
                  </b-td>
                </template>
                <template v-if="containsCustomGateways">
                  <b-td v-if="device.gatewayId === 'cloud'">CLOUD</b-td>
                  <b-td v-else-if="GetGateway(device.gatewayId) === null"
                    >No gateway route</b-td
                  >
                  <b-td v-else>
                    <div
                      router-link
                      :to="`/Networks/${$route.params.serviceId}/Gateways/${device.gatewayId}`"
                    >
                      <span b-button variant="default">{{
                        GetGateway(device.gatewayId).name
                      }}</span>
                    </div>
                  </b-td>
                </template>
                <b-td>
                  <b-button
                    variant="success"
                    v-if="device.authorized === false"
                    @click="adoptAndActivateDevice(device)"
                    style="margin-right: 10px"
                    >Adopt</b-button
                  >
                  <!-- <b-button
                    variant="info"
                    @click="editDevice(device)"
                    style="color: #fff"
                    >View/Edit</b-button
                  > -->
                  <b-button variant="link" size="sm" @click="showLogs(device)">
                    <i class="flaticon2-list"></i
                  ></b-button>
                  <b-button
                    variant="link"
                    size="sm"
                    @click="editDevice(device)"
                  >
                    <i class="flaticon2-settings"></i>
                  </b-button>
                  <div class="dropdown dropdown-inline" v-if="false">
                    <b-button
                      variant="link"
                      size="sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="flaticon2-settings"></i
                    ></b-button>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-fit
                        dropdown-menu-md
                      "
                    >
                      <div style="padding: 20px">
                        <h6>Quick Actions</h6>
                        <b-button
                          :disabled="rebooting.indexOf(device.id) >= 0"
                          style="
                            margin-left: 10px;
                            margin-bottom: 10px;
                            padding: 5px 10px 5px 10px;
                            color: #ffff;
                          "
                          variant="danger"
                          @click="rebootTR069(device)"
                          v-if="
                            device.active === true && device.status === true
                          "
                        >
                          <b-spinner
                            v-if="rebooting.indexOf(device.id) >= 0"
                            style="
                              text-align: center;
                              color: var(--kt-font-color);
                            "
                          ></b-spinner>
                          <v-icon style="color: #ffffff" v-else
                            >mdi-refresh</v-icon
                          >
                          Reboot
                        </b-button>
                        <b-button
                          :disabled="syncing.indexOf(device.id) >= 0"
                          style="
                            margin-left: 10px;
                            margin-bottom: 10px;
                            padding: 5px 10px 5px 10px;
                            color: #ffff;
                          "
                          variant="info"
                          @click="syncTR069(device)"
                          v-if="
                            device.active === true && device.status === true
                          "
                        >
                          <b-spinner
                            v-if="syncing.indexOf(device.id) >= 0"
                            style="
                              text-align: center;
                              color: var(--kt-font-color);
                            "
                          ></b-spinner>
                          <v-icon style="color: #ffffff" v-else
                            >mdi-refresh</v-icon
                          >
                          Re-Sync
                        </b-button>
                        <!-- <b-form-group label="View">
                          <b-form-radio
                            v-model="showTreeView"
                            size="lg"
                            :value="false"
                            >Table view</b-form-radio
                          >
                          <b-form-radio
                            v-model="showTreeView"
                            size="lg"
                            :value="true"
                            >Hierarchical view</b-form-radio
                          >
                        </b-form-group> -->
                      </div>
                    </div>
                  </div>
                  <!-- <router-link :to="`/Networks/Devices/${device.id}`">
                    <b-button variant="info">View/Edit</b-button>
                  </router-link>-->
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
    </div>
    <YSIDEBAR ref="sidebar">
      <template
        slot="header"
        v-if="!$tools.isNullOrUndefined(editDeviceObject)"
      >
        <h3 style="padding: 0 20px 0 20px">
          Configure {{ editDeviceRefObject.name || "New device" }}
        </h3>
      </template>
      <Loader v-if="editDeviceObjectSaving === true" :inContent="true"
        >Loading...</Loader
      >
      <template v-else-if="!$tools.isNullOrUndefined(editDeviceObject)">
        <v-form ref="form" v-model="editDeviceObjectValid" lazy-validation>
          <div style="padding: 20px">
            <v-text-field
              v-model="editDeviceObject.name"
              type="text"
              label="Name"
              :rules="editDeviceRules"
              :required="true"
            ></v-text-field>
            <v-checkbox
              v-if="
                !editDeviceObject.authorized || !editDeviceRefObject.authorized
              "
              v-model="editDeviceObject.authorized"
              label="Authorize"
              hint="Authorize Device"
              :persistent-hint="true"
            ></v-checkbox>
            <v-checkbox
              v-model="editDeviceObject.active"
              label="Active"
              hint="Disable/Enable Device"
              :persistent-hint="true"
            ></v-checkbox>
            <!-- <v-checkbox
              v-model="editDeviceObject.syslog"
              label="Allow SYSLOG"
              hint="Enable syslog server for device"
              :persistent-hint="true"
            ></v-checkbox>
            <v-checkbox
              v-model="editDeviceObject.ping"
              label="Perform PING"
              hint="Ping the device every minute (uptime monitoring)"
              :persistent-hint="true"
            ></v-checkbox>
            <v-checkbox
              v-model="editDeviceObject.snmp"
              label="Perform SNMP"
              hint="Pull data using SNMP"
              :persistent-hint="true"
            ></v-checkbox>
            <v-checkbox
              v-model="editDeviceObject.netflow"
              label="Receive Traffic Flows - v9"
              hint="Collect traffic flow data"
              :persistent-hint="true"
            ></v-checkbox> -->
            <div>
              <div
                :style="`display: inline-block; width: ${
                  editDeviceObject.templateId === null
                    ? '100%'
                    : 'calc(100% - 42px)'
                };`"
              >
                <!-- <v-autocomplete
                  label="Device template"
                  v-if="!$tools.isNullOrUndefined(templates)"
                  :items="getTemplates"
                  item-text="name"
                  item-value="value"
                  v-model="editDeviceObject.templateId"
                  xplaceholder="Start typing to Search"
                ></v-autocomplete> -->
              </div>
              <div
                style="display: inline-block; margin-left: 5px"
                v-if="editDeviceObject.templateId !== null"
              >
                <!-- <b-btn @click="editDeviceObject.templateId = null" small text
                  >X</b-btn
                > -->
                <!-- <v-text-field
                  label="Device template"
                  v-if="!$tools.isNullOrUndefined(templates)"
                  :readonly="true"
                  :value="getTemplateName(editDeviceObject.templateId)"
                ></v-text-field> -->
              </div>
            </div>
            <!-- <v-checkbox
              v-if="editDeviceObject.templateId !== null"
              v-model="editDeviceObject.newTemplate"
              label="Create a new template and assign the device to it"
              hint="Create new child template"
              :persistent-hint="true"
            ></v-checkbox>
            <div
              v-if="editDeviceObject.templateId !== null"
              style="width: 10px; height: 50px"
            ></div> -->

            <b-button
              style="margin-right: 20px"
              :disabled="!editDeviceObjectValid"
              @click="saveEditDevice"
              :variant="editDeviceObjectValid ? 'success' : 'warning'"
              >SAVE</b-button
            >
            <!-- <div style="width: 10px; height: 50px"></div> -->
            <!-- <b-button
              style="margin-right: 20px"
              :disabled="removeDeviceBusy"
              @click="removeDevice()"
              variant="danger"
              >{{
                removeDeviceBusy
                  ? "WAIT!"
                  : removeDeviceConfirm
                  ? "ARE YOU SURE?"
                  : "REMOVE"
              }}</b-button
            > -->
          </div>
        </v-form>

        <div
          style="padding: 20px; margin-top: 20px; position: absolute; bottom: 0;"
          v-if="!$tools.isNullOrUndefined(editDeviceRefObject)"
        >
          <h6>Quick Actions</h6>
          <b-button
            :disabled="rebooting.indexOf(editDeviceRefObject.id) >= 0"
            style="
              margin-left: 10px;
              margin-bottom: 10px;
              padding: 5px 10px 5px 10px;
              color: #ffff;
            "
            variant="danger"
            @click="rebootTR069(editDeviceRefObject)"
            v-if="
              editDeviceRefObject.active === true &&
              editDeviceRefObject.status === true
            "
          >
            <b-spinner
              v-if="rebooting.indexOf(editDeviceRefObject.id) >= 0"
              style="text-align: center; color: var(--kt-font-color)"
            ></b-spinner>
            <v-icon style="color: #ffffff" v-else>mdi-refresh</v-icon>
            Reboot
          </b-button>
          <b-button
            :disabled="syncing.indexOf(editDeviceRefObject.id) >= 0"
            style="
              margin-left: 10px;
              margin-bottom: 10px;
              padding: 5px 10px 5px 10px;
              color: #ffff;
            "
            variant="info"
            @click="syncTR069(editDeviceRefObject)"
            v-if="
              editDeviceRefObject.active === true &&
              editDeviceRefObject.status === true
            "
          >
            <b-spinner
              v-if="syncing.indexOf(editDeviceRefObject.id) >= 0"
              style="text-align: center; color: var(--kt-font-color)"
            ></b-spinner>
            <v-icon style="color: #ffffff" v-else>mdi-refresh</v-icon>
            Re-Sync
          </b-button>
          <b-button
            style="
              margin-left: 10px;
              margin-bottom: 10px;
              padding: 5px 10px 5px 10px;
              color: #ffff;
            "
            variant="primary"
            @click="showLogs(editDeviceRefObject)"
          >
            <i class="flaticon2-list"></i>
            Logs/Events
          </b-button>
          <!-- <b-form-group label="View">
                          <b-form-radio
                            v-model="showTreeView"
                            size="lg"
                            :value="false"
                            >Table view</b-form-radio
                          >
                          <b-form-radio
                            v-model="showTreeView"
                            size="lg"
                            :value="true"
                            >Hierarchical view</b-form-radio
                          >
                        </b-form-group> -->
        </div>
      </template>
    </YSIDEBAR>
    <GROUPSETUP ref="groupSetup" />
    <DeviceLogs ref="deviceLogs" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";
//import Swal from "sweetalert2";
import TLib from "./lib";
import DEVICE_STATE from "./devices.state";
import GROUP_SETUP from "./group_setup";
import DeviceLogs from "@/views/pages/betternetworks/event_logs.vue";

export default {
  components: {
    YSIDEBAR: Y_SIDEBAR,
    GROUPSETUP: GROUP_SETUP,
    KTPortlet,
    Loader,
    DeviceLogs,
    deviceState: DEVICE_STATE,
  },
  watch: {
    $route() {
      const oldFilter = `${this.filterByGroupId || ""}`;
      const newFilter = `${this.$route.query.groupfilter || ""}`;
      if (newFilter !== oldFilter) {
        this.$data.filterByGroupId = this.$route.query.groupfilter || null;
      }
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      devices: null,
      types: null,
      templates: null,
      gateways: null,
      groups: null,
      editDeviceObject: null,
      editDeviceRefObject: null,
      editDeviceObjectValid: true,
      editDeviceObjectSaving: false,
      rebooting: [],
      rebootingTimers: [],
      syncing: [],
      syncingTimers: [],
      removeDeviceConfirm: false,
      removeDeviceBusy: false,

      containsAssignedTemplates: false,
      containsCustomGateways: false,
      hasDevicesCache: false,

      filterByGroupId: null,
    };
  },
  methods: {
    getTemplateName(templateId) {
      if (this.templates === null) return "";
      for (let template of this.templates)
        if (template.id === templateId) return template.name;
      return "";
    },
    showLogs(device) {
      this.$refs.sidebar.hide();
      this.$refs.deviceLogs.show("device", device.id, device.name);
    },
    getGroup(groupId) {
      for (let group of this.groups) {
        if (group.id === groupId) return group;
      }
      //console.log("NO FIND GROUP", groupId);
      return null;
    },
    adoptAndActivateDevice(device) {
      this.editDevice(device);
      this.$data.editDeviceObject.authorized = true;
      this.$data.editDeviceObject.active = true;
      this.saveEditDevice();
    },
    removeDevice() {
      if (this.$data.removeDeviceBusy) return;
      let self = this;
      if (this.removeDeviceConfirm) {
        this.$data.removeDeviceBusy = true;
        TLib.removeDevice(
          self,
          self.$route.params.serviceId,
          self.editDeviceRefObject.id
        )
          .then(() => {
            self
              .updateData()
              .then(() => {
                self.$data.removeDeviceConfirm = false;
                self.$data.removeDeviceBusy = false;
                self.$refs.sidebar.hide();
              })
              .catch(self.$log.error);
          })
          .catch((xc) => {
            self.$log.error(xc);
            //console.error("Error deleting device");
            self.$data.removeDeviceConfirm = false;
            self.$data.removeDeviceBusy = false;
          });
      } else {
        this.$data.removeDeviceBusy = true;
        setTimeout(() => {
          self.$data.removeDeviceConfirm = true;
          self.$data.removeDeviceBusy = false;
        }, 5000);
      }
    },
    async saveEditDevice() {
      let self = this;
      self.$data.editDeviceObjectSaving = true;
      if (self.editDeviceObject.newTemplate === true) {
        self.$data.editDeviceObject.templateId = await TLib.newTemplate(
          self,
          self.$route.params.serviceId,
          self.editDeviceObject.templateId,
          self.editDeviceRefObject.name
        );
        await TLib.getTemplates(self, self.$route.params.serviceId, null, true);
      }
      TLib.saveDevice(
        self,
        self.$route.params.serviceId,
        self.editDeviceRefObject.id,
        self.editDeviceObject
      )
        .then(() => {
          self
            .updateData()
            .then(() => {
              self.$data.editDeviceObjectSaving = false;
              self.$refs.sidebar.hide();
            })
            .catch(self.$log.error);
        })
        .catch((x) => {
          //console.error("error while saving");
          self.$log.error(x);
          self.$data.editDeviceObjectSaving = false;
        });
    },
    editDevice(device) {
      this.$data.editDeviceObjectSaving = false;
      this.$data.editDeviceRefObject = JSON.parse(JSON.stringify(device));
      this.$data.editDeviceObject = JSON.parse(JSON.stringify(device));
      this.$data.editDeviceObject.newTemplate = false;
      this.$refs.sidebar.show();
    },
    rebootTR069(device) {
      this.$log.log("Reboot: " + device.id);
      if (this.$tools.isNullOrUndefined(device)) return;
      this.$data.rebooting.push(device.id);
      let self = this;
      let timrIndex = self.rebootingTimers.length;
      TLib.rebootDevice(self, self.$route.params.serviceId, device.id)
        .then(() => {
          self.showLogs(device);
          self.$data.rebootingTimers.push(
            setTimeout(() => {
              self.$data.rebooting.splice(self.rebooting.indexOf(device.id), 1);
              self.$data.rebootingTimers.splice(timrIndex, 1);
            }, 5000)
          );
        })
        .catch((x) => {
          self.$log.error(x);
          self.$data.rebooting.splice(
            self.$data.rebooting.indexOf(device.id),
            1
          );
        });
    },
    syncTR069(device) {
      this.$log.log("Sync: " + device.id);
      if (this.$tools.isNullOrUndefined(device)) return;
      this.$data.syncing.push(device.id);
      let self = this;
      let timrIndex = self.syncingTimers.length;
      TLib.syncDevice(self, self.$route.params.serviceId, device.id)
        .then(() => {
          self.showLogs(device);
          self.$data.syncingTimers.push(
            setTimeout(() => {
              self.$data.syncing.splice(self.syncing.indexOf(device.id), 1);
              self.$data.syncingTimers.splice(timrIndex, 1);
            }, 5000)
          );
        })
        .catch((x) => {
          self.$log.error(x);
          self.$data.syncing.splice(self.$data.syncing.indexOf(device.id), 1);
        });
    },
    GetRouterType(typeId) {
      for (let tType of this.types) if (tType.id === typeId) return tType;

      return null;
    },
    GetTemplate(templateId) {
      for (let templ of this.templates) {
        if (templ.id === templateId) return templ;
      }
      return null;
    },
    GetGateway(gatewayId) {
      for (let templ of this.gateways) {
        if (templ.id === gatewayId) return templ;
      }
      return null;
    },
    updateSpecificInfo() {
      //console.log("UPDATE SPECIFIC DATA");
      const self = this;
      self.$data.containsCustomGateways = false;
      self.$data.containsAssignedTemplates = false;
      for (let device of self.devices) {
        if (device.gatewayId !== null) {
          self.$data.containsCustomGateways = true;
          break;
        }
      }
      for (let device of self.devices) {
        if (device.templateId !== null) {
          self.$data.containsAssignedTemplates = true;
          break;
        }
      }
    },
    updateData(bumpCache = false) {
      return new Promise((resolve) => {
        let self = this;
        if (!bumpCache) self.$data.devices = null;
        else self.$data.hasDevicesCache = false;
        TLib.getGroups(self, self.$route.params.serviceId, bumpCache)
          .then((data) => {
            if (bumpCache) {
              self.$data.groups = [];
              return self.$nextTick(() => {
                self.$data.groups = data;
                resolve();
              });
            }
            self.$data.groups = data;
            resolve();
          })
          .catch((x) => {
            self.$log.error(x);
            //console.error("Error loading content");
          });
        TLib.getTypes(self, self.$route.params.serviceId)
          .then((typex) => {
            self.$data.types = typex;
            TLib.getTemplates(
              self,
              self.$route.params.serviceId,
              undefined,
              bumpCache
            )
              .then((templatex) => {
                self.$data.templates = templatex;
                TLib.getGateways(
                  self,
                  self.$route.params.serviceId,
                  undefined,
                  bumpCache
                )
                  .then((gatewaysz) => {
                    self.$data.gateways = gatewaysz;
                    TLib.getDevices(
                      self,
                      self.$route.params.serviceId,
                      undefined,
                      bumpCache
                    )
                      .then((data) => {
                        if (bumpCache) {
                          self.$data.devices = [];
                          return self.$nextTick(() => {
                            self.$data.devices = data;
                            self.$nextTick(() => {
                              self.updateSpecificInfo();
                              resolve();
                            });
                          });
                        }
                        self.$data.devices = data;
                        self.$nextTick(() => {
                          self.updateSpecificInfo();
                          resolve();
                        });
                      })
                      .catch((x) => {
                        self.$log.error(x);
                        //console.error("Error loading content");
                      });
                  })
                  .catch((x) => {
                    self.$log.error(x);
                    //console.error("Error loading content");
                  });
              })
              .catch((x) => {
                self.$log.error(x);
                //console.error("Error loading content");
              });
          })
          .catch((x) => {
            self.$log.error(x);
            //console.error("Error loading content");
          });
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    groupedDevices() {
      if (this.devices === null) return [];
      let grouped = {};

      for (let device of this.devices) {
        if (this.filterByGroupId !== null) {
          if (this.filterByGroupId !== device.groupId) continue;
        }
        grouped[device.groupId] = grouped[device.groupId] || [];
        grouped[device.groupId].push(device);
      }

      let groups = [];
      for (let group of Object.keys(grouped)) {
        for (let i = 0; i < grouped[group].length - 1; i++) {
          for (let j = i + 1; j < grouped[group].length; j++) {
            if (grouped[group][i].id > grouped[group][j].id) {
              let tmp = grouped[group][j];
              grouped[group][j] = grouped[group][i];
              grouped[group][i] = tmp;
            }
          }
        }
        groups.push({
          groupId: group,
          group: this.getGroup(group),
          devices: grouped[group],
        });
      }

      for (let i = 0; i < groups.length - 1; i++) {
        for (let j = i + 1; j < groups.length; j++) {
          if (groups[i].groupId > groups[j].groupId) {
            let tmp = groups[j];
            groups[j] = groups[i];
            groups[i] = tmp;
          }
        }
      }

      return groups;
    },

    editDeviceRules() {
      let self = this;
      let listORules = [];
      listORules.push((v) => {
        if (self.$tools.isNullOrUndefined(v) || v === "")
          return `Field is required`;
        let minLength = 5;
        if (`${v}`.length < minLength)
          return `${minLength - `${v}`.length} more characters required`;
        return null;
      });
      return [
        (v) => {
          for (let rule of listORules) {
            let result = rule(v);
            if (!self.$tools.isNull(result)) return result;
          }
          return true;
        },
      ];
    },
    getTemplates() {
      if (this.templates === null) return [];
      let listOfTemplates = [];

      for (let templ of this.templates) {
        if (templ.deleted === true) continue;
        listOfTemplates.push({
          name: templ.name,
          value: templ.id,
        });
      }

      return listOfTemplates;
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off("ws-networks-tr069-device-informed");
    this.$eventBus.off("ws-networks-tr069-device-added");
    this.$eventBus.off("ws-networks-tr069-device-adopted");
    this.$eventBus.off("ws-networks-device-added");
    //this.$eventBus.off("ws-networks-device-updated");
    this.$eventBus.off("ws-networks-device-removed");
    this.$eventBus.off("ws-networks-template-added");
    this.$eventBus.off("ws-networks-template-updated");
    //this.$eventBus.off("ws-networks-device-updated");
    //this.$eventBus.off("ws-networks-device-updated-status");
    for (let timr of this.rebootingTimers) {
      clearTimeout(timr);
    }
    for (let timr of this.syncingTimers) {
      clearTimeout(timr);
    }
  },
  mounted() {
    this.$data.filterByGroupId = this.$route.query.groupfilter || null;
    this.$data.hasDevicesCache = TLib.hasDevicesCache(
      this,
      this.$route.params.serviceId
    );
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Devices" },
    ]);

    let self = this;
    self.updateData();
    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
    const deviceUpdateTrigger = async (data) => {
      let deviceFound = false;
      if (!self.$tools.isNullOrUndefined(self.devices)) {
        for (let i = 0; i < self.devices.length; i++) {
          if (self.devices[i].id === data.id) {
            for (let key of Object.keys(data)) {
              self.devices[i][key] = data[key];
            }
            deviceFound = true;
            break;
          }
        }
      }
      if (!deviceFound) {
        self.updateData(true);
        return;
      }
      self.$nextTick(() => {
        TLib.forceDevicesCacheUpdate(
          self,
          self.$route.params.serviceId,
          self.devices
        );
        self.updateSpecificInfo();
      });
    };
    this.$eventBus.on("ws-networks-tr069-device-informed", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-tr069-device-added", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-tr069-device-adopted", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-device-added", deviceUpdateTrigger);
    //this.$eventBus.on("ws-networks-device-updated", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-device-removed", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-templated-added", () => {
      self.updateData(true);
    });
    this.$eventBus.on("ws-networks-templated-updated", () => {
      self.updateData(true);
    });
    /*this.$eventBus.on("ws-networks-device-updated", () => {
      self.updateData(true);
    });
    this.$eventBus.on("ws-networks-device-status", (status) => {
      if (self.$tools.isNullOrUndefined(self.devices)) return;

      for (let i = 0; i < self.devices.length; i++) {
        if (self.devices[i].id === status.deviceId) {
          self.devices[i].status = status.status;
          self.devices[i].lastPing = status.lastPing;
          self.devices[i].lastIP = status.lastIP;
          self.devices[i].lastIPPing = status.lastIPPing;
          return;
        }
      }
    });*/
  },
};
</script>
