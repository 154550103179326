<template>
  <YSIDEBAR ref="groupSetup" @hide="$emit('hide')">
    <template slot="header">
      <h3 style="padding: 0 20px 0 20px">New Device Setup</h3>
    </template>
    <Loader v-if="groups === null" :inContent="true">Loading...</Loader>
    <template v-else-if="selectedGroup === null && groups.length > 0">
      <h5 style="padding: 0 20px 0 20px">Select the group to use:</h5>
      <div :style="`display: inline-block; width: 100%; padding: 20px;`">
        <b-table-simple responsive>
          <b-tbody>
            <b-tr v-for="group in groups" v-bind:key="group.id">
              <b-th sticky-column>{{ group.name }}</b-th>
              <b-td>
                <b-button
                  @click="
                    $router.push(
                      `/Networks/${$route.params.serviceId}/Groups?action=setup&group=${group.id}`
                    )
                  "
                  variant="success"
                  style="color: #fff"
                  >SELECT</b-button
                >
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div style="width: 10px; height: 30px"></div>
        <div style="padding: 0 20px 0 20px; text-align: center">
          <h5>Can't find a group?</h5>
          <b-button
            @click="
              $router.push(
                `/Networks/${$route.params.serviceId}/Groups?action=add`
              )
            "
            variant="info"
            style="color: #fff"
            >ADD GROUP</b-button
          >
        </div>
      </div>
    </template>
    <template v-else-if="selectedGroup === null">
      <div style="padding: 0 20px 0 20px; text-align: center">
        <h5>To adopt a new device, you need to create a group first.</h5>
        <b-button
          @click="
            emitGroupEvent === true
              ? $emit('newGroup')
              : $router.push(
                  `/Networks/${$route.params.serviceId}/Groups?action=add`
                )
          "
          variant="info"
          style="color: #fff"
          >NEW GROUP</b-button
        >
      </div>
    </template>
    <template v-else>
      <h5 style="padding: 0 20px 0 20px">
        For: <b>{{ selectedGroup.name }}</b>
      </h5>
      <v-tabs :centered="true" :grow="true">
        <v-tab> Standard TR069 Device </v-tab>
        <v-tab> Mikrotik RouterOS </v-tab>

        <v-tab-item>
          <Loader v-if="groupSetupConfig === null" :inContent="true"
            >Loading...</Loader
          >
          <div v-else style="padding: 20px">
            <v-text-field
              v-model="groupSetupConfig.url"
              type="text"
              label="URL"
              :disabled="true"
            ></v-text-field>
            <span
              style="margin-top: -23px; position: absolute; cursor: pointer"
              @click="$tools.copy(groupSetupConfig.url)"
              >COPY</span
            >
            <v-text-field
              v-model="groupSetupConfig.username"
              type="text"
              label="Username"
              :disabled="true"
            ></v-text-field>
            <span
              style="margin-top: -23px; position: absolute; cursor: pointer"
              @click="$tools.copy(groupSetupConfig.username)"
              >COPY</span
            >
            <v-text-field
              v-model="groupSetupConfig.password"
              type="text"
              label="Password"
              :disabled="true"
            ></v-text-field>
            <span
              style="margin-top: -23px; position: absolute; cursor: pointer"
              @click="$tools.copy(groupSetupConfig.password)"
              >COPY</span
            >
            <div style="margin-top: 50px"></div>
            <b-button
              v-if="!$tools.isNullOrUndefined(groupSetupConfig.caCertPath)"
              @click="downloadCACert()"
              variant="info"
              style="color: #fff"
              >DOWNLOAD CA CERT</b-button
            >
          </div>
        </v-tab-item>
        <v-tab-item>
          <Loader v-if="groupSetupConfig === null" :inContent="true"
            >Loading...</Loader
          >
          <div v-else style="padding: 20px">
            <v-text-field
              v-model="mikSetupScript"
              type="text"
              label="Mik Simple Setup Script"
              :disabled="true"
            ></v-text-field>
            <span
              style="margin-top: -23px; position: absolute; cursor: pointer"
              @click="$tools.copy(mikSetupScript)"
              >COPY</span
            >
          </div>
        </v-tab-item>
      </v-tabs>
    </template>
  </YSIDEBAR>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/views/partials/content/Loader.vue";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";
//import Swal from "sweetalert2";
import TLib from "./lib";
import ApiService from "@/common/api.service";

export default {
  props: ["emitGroupEvent"],
  components: {
    YSIDEBAR: Y_SIDEBAR,
    Loader,
  },
  watch: {},
  data() {
    return {
      groups: null,
      selectedGroup: null,
      groupSetupConfig: null,
    };
  },
  methods: {
    downloadCACert() {
      ApiService.triggerDownloadFile(
        this.groupSetupConfig.caCertPath,
        "BetterNetworksCACert.crt",
        undefined,
        true
      );
    },
    hide() {
      this.$refs.groupSetup.hide();
    },
    show(groupId) {
      const self = this;
      this.updateData()
        .then((groups) => {
          for (let group of groups) {
            if (group.id === groupId) {
              self.$nextTick(() => {
                self.updateConnectionData(group);
                self.$refs.groupSetup.show();
              });
              return;
            }
          }
          self.$refs.groupSetup.show();
        })
        .catch(self.$log.error);
    },
    updateData() {
      return new Promise((resolve, reject) => {
        let self = this;
        self.$data.groups = null;
        self.$data.selectedGroup = null;
        self.$data.groupSetupConfig = null;
        //if (bumpCache) await new Promise((resolve) => setTimeout(resolve, 1000));
        TLib.getGroups(self, self.$route.params.serviceId)
          .then((data) => {
            self.$data.groups = data;
            resolve(data);
          })
          .catch((x) => {
            self.$log.error(x);
            reject(x);
            //console.error("Error loading content");
          });
      });
    },
    updateConnectionData(group) {
      const self = this;
      self.$data.selectedGroup = group;
      TLib.getGroupConnection(
        self,
        self.$route.params.serviceId,
        this.$data.selectedGroup.id
      )
        .then((data) => {
          self.$data.groupSetupConfig = data.data;
        })
        .catch((x) => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    mikSetupScript() {
      let lines = [
        `/tool fetch url=https://content.betterweb.co.za/uploads/CACerts-BetterPortal.cert mode=https dst-path=CACerts-BetterPortal.cert; `,
        `/delay 5s; `,
        `/certificate import file-name=CACerts-BetterPortal.cert passphrase=""; `,
        `/delay 5s; `,
        `/file remove CACerts-BetterPortal.cert; `,
        `/tr069-client set acs-url="${this.groupSetupConfig.url}" periodic-inform-enabled=yes periodic-inform-interval=30s username="${this.groupSetupConfig.username}" password="${this.groupSetupConfig.password}" enabled=yes; `,
      ];
      return lines.join("");
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
  },
  mounted() {
    const self = this;
    this.updateData();
    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
  },
};
</script>
