<template>
  <div>
    <YSIDEBAR ref="eventLogs" @hide="$emit('hide')">
      <template slot="header">
        <h3 style="padding: 0 20px 0 20px">Logs for {{ forName }}</h3>
      </template>
      <Loader v-if="logs === null" :inContent="true">Loading...</Loader>
      <template v-else-if="logs.length > 0">
        <div :style="`display: inline-block; width: 100%; padding: 20px;`">
          <v-card
            v-for="log in logs"
            v-bind:key="log.id"
            :style="`padding: 10px; margin-bottom: 10px;color: var(--${getLogCol(
              log.action,
              log
            )});`"
          >
            <!-- <v-icon :style="`color: var(--${getLogCol(log.action)})`">{{
              getLogIcon(log.action, log)
            }}</v-icon> -->
            <h4>{{ getLogDesc(log) }}</h4>
            <div
              style="
                text-align: left;
                width: 100%;
                padding: 10px;
                line-height: 0;
              "
            >
              by
              <b style="font-weight: 500">{{ getUserName(log.userId, log) }}</b>
              on
              <b style="font-weight: 500">{{ formatDate(log.date, log) }}</b>
            </div>
          </v-card>
        </div>
      </template>
      <template v-else>
        <div style="padding: 0 20px 0 20px; text-align: center">
          <h5>No logs yet...</h5>
        </div>
      </template>
    </YSIDEBAR>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/views/partials/content/Loader.vue";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";
//import Swal from "sweetalert2";
import TLib from "./lib";
import * as moment from "moment";

export default {
  components: {
    YSIDEBAR: Y_SIDEBAR,
    Loader,
  },
  watch: {},
  data() {
    return {
      logs: null,
      forName: null,
      forId: null,
      forType: null,
    };
  },
  methods: {
    getLogDesc(log) {
      if (
        log.action === "device-log" &&
        log.meta !== undefined &&
        log.meta !== null &&
        log.meta.log !== undefined &&
        log.meta.log !== null &&
        log.meta.log !== ''
      ) {
        return log.meta.log;
      }
      return log.desc;
    },
    getUserName(uid , log) {
      if (!this.$tools.isNullOrUndefined(log.byName)) return log.byName;
      if (this.$tools.isNullOrUndefined(uid)) return "system";
      if (this.userId == uid) return "you";
      return "someone else";
    },
    formatDate(dateT /*, log*/) {
      return moment(dateT).format("DD/MM/YYYY HH:mm:ss");
    },
    getLogIcon(action, log) {
      return this.getLogKey(action, log).icon;
    },
    getLogCol(action, log) {
      return this.getLogKey(action, log).col;
    },
    getLogKey(action, log) {
      if (action === "device-action-reboot")
        return { icon: "mdi-sync", col: "danger" };
      if (action === "device-action-sync")
        return { icon: "mdi-sync", col: "warning" };
      if (
        action === "device-log" &&
        log.meta !== undefined &&
        log.meta !== null &&
        log.meta.level !== undefined &&
        log.meta.level !== null
      ) {
        //debug = 0,
        //info = 1,
        //warning = 2,
        //error = 3,
        //fatal = 4,
        if (log.meta.level == 0 || log.meta.level == 1)
          return { icon: "mdi-message-text-outline", col: "primary" };
        if (log.meta.level == 2)
          return { icon: "mdi-message-text-outline", col: "warning" };
        if (log.meta.level == 3 || log.meta.level == 4)
          return { icon: "mdi-message-text-outline", col: "danger" };
      }
      return { icon: "mdi-message-text-outline", col: "primary" };
    },
    hide() {
      this.$refs.eventLogs.hide();
    },
    updateData() {
      const self = this;
      TLib.getLogs(self, self.$route.params.serviceId, self.forType, self.forId)
        .then((data) => {
          self.$data.logs = data;
        })
        .catch((x) => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
    show(typeFor, forId, forName) {
      const self = this;
      self.$data.forName = forName;
      self.$data.forId = forId;
      self.$data.forType = typeFor;
      self.$data.logs = null;
      self.$refs.eventLogs.show();
      self.updateData();
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "userId"]),

    config() {
      return this.layoutConfig();
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`ws-networks-log-added`);
  },
  created() {
    const self = this;
    this.updateData();
    this.$eventBus.on(`ws-networks-log-added`, (data) => {
      //console.log("LOG EVENT", data);
      if (data.type !== self.forType) return;
      if (data.forId !== self.forId) return;
      self.updateData();
    });
  },
};
</script>
