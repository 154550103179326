<template>
  <!-- begin::Quick Panel -->
  <div
    :id="`kt_quick_panel_${thisID}`"
    ref="kt_quick_panel"
    class="kt-quick-panel"
  >
    <button class="kt-quick-panel__close" @click="hide()">
      <i class="flaticon2-delete"></i>
    </button>

    <div class="kt-quick-panel__nav">
      <slot name="header" />
    </div>

    <div class="kt-quick-panel__content">
      <div class="tab-content" :data-app="thisState">
        <slot v-if="thisState || resetContentOnChange === false" />
      </div>
    </div>
  </div>
  <!-- end::Quick Panel -->
</template>

<script>
import KTOffcanvas from "@/assets/js/offcanvas.js";

export default {
  name: "KTQuickPanel",
  props: ["resetContentOnChange"],
  components: {},
  computed: {},
  data() {
    return {
      thisID: null,
      canvas: null,
      thisState: false
    };
  },
  mounted() {
    let thisID = this.$tools.generateNewGuid();
    this.$data.thisID = thisID;
    this.$data.canvas = new KTOffcanvas(this.$refs["kt_quick_panel"], {
      overlay: true,
      baseClass: "kt-quick-panel"
    });

    let self = this;
    this.$data.canvas.on("afterHide", () => {
      self.$data.thisState = false;
      self.$emit("hide");
    });
    this.$data.canvas.on("afterShow", () => {
      self.$data.thisState = true;
      self.$emit("show");
    });
  },
  methods: {
    show() {
      this.$data.thisState = true;
      this.canvas.show();
      this.$emit("show");
    },
    hide() {
      this.$data.thisState = false;
      this.canvas.hide();
      this.$emit("hide");
    }
  }
};
</script>
